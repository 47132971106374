<div class="login-page">
  <div class="alert-container alert alert-danger alert-dismissible fade show" role="alert" *ngIf="loginError"
       [innerHTML]="loginError">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <img src="/assets/images/roseLU.png" class="rose roseLU" alt="Rose Left Upper">
  <img src="/assets/images/roseRD.png" class="rose roseRD" alt="Rose Right Down">
  <img src="/assets/images/roseRU.png" class="rose roseRU" alt="Rose Right Upper">
  <form class="login-form login-panel fade-in align-content-center" (ngSubmit)="login()">
    <div class="form-group">
      <input type="password" class="form-control" [class.shake-animation]="loginError" id="pwd"
             placeholder="Enter password" name="pswd" [(ngModel)]="password" required>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <button type="submit" class="btn btn-primary" aria-label="Login button">Login</button>
    </div>
  </form>
</div>
