import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent implements OnInit {
  storageKey: string = 'data-bs-theme';

  constructor() {
  }

  ngOnInit(): void {
    this.loadPreference();

    const themeToggle = document.getElementById('theme-toggle');
    if (themeToggle) {
      themeToggle.addEventListener('click', () => {
        const currentTheme = document.documentElement.getAttribute(this.storageKey);
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        document.documentElement.setAttribute(this.storageKey, newTheme);
        this.setPreference(newTheme);

        if (currentTheme == 'light') {
          document.getElementById("sun").setAttribute('transform', 'scale(1.75)');
          document.getElementById("sun-beams").setAttribute('opacity', '0');
          document.getElementById("circle").setAttribute('cx', '17');
          document.getElementById("circle").setAttribute('transform', 'translate(0)');
        } else {
          document.getElementById("sun").setAttribute('transform', 'scale(1)');
          document.getElementById("sun-beams").setAttribute('opacity', '100');
          document.getElementById("circle").setAttribute('cx', '24');
          document.getElementById("circle").setAttribute('transition', 'cx .25s cubic-bezier(0, 0, 0, 1)');
        }
      });
    }
  }

  setPreference(theme: string) {
    localStorage.setItem(this.storageKey, theme);
  }

  private loadPreference() {
    const themePreference = localStorage.getItem(this.storageKey);
    if (themePreference) {
      document.documentElement.setAttribute(this.storageKey, themePreference);
    } else {
      this.setPreference('light');
    }

    if (themePreference == 'dark') {
      document.getElementById("sun").setAttribute('transform', 'scale(1.75)');
      document.getElementById("sun-beams").setAttribute('opacity', '0');
      document.getElementById("circle").setAttribute('cx', '17');
      document.getElementById("circle").setAttribute('transform', 'translate(0)');
    } else {
      document.getElementById("sun").setAttribute('transform', 'scale(1)');
      document.getElementById("sun-beams").setAttribute('opacity', '100');
      document.getElementById("circle").setAttribute('cx', '24');
      document.getElementById("circle").setAttribute('transition', 'cx .25s cubic-bezier(0, 0, 0, 1)');
    }
  }
}
