<header class="nav-links navbar-expand-md row" id="header">
  <div class="col-1 d-flex align-items-center p-0">
    <a class="header-logo_link navbar-brand" href="/home" title="Our wedding page">
      <img class="logo img-fluid" src="/assets/logo.png" alt="Our wedding logo" width="50px" height="50px">
    </a>
  </div>
  <div class="header-container col-6 collapse navbar-collapse justify-content-between" id="collapsibleNavbar">
    <nav class="header-menu_list navbar-nav nav nav-justified" *ngFor="let item of items">
      <a *ngIf="router.url === ('/' +item.link)"
         [routerLink]="item.link"
         class="nav-item nav-link active active-link"
         aria-current="page">{{ item.title }}</a>
      <a *ngIf="router.url !== ('/' +item.link)" [routerLink]="item.link" class="nav-item nav-link">{{ item.title }}</a>
    </nav>

    <div class="lang-col-container d-flex">
      <div class="lang-switch">
        <app-lang-switch></app-lang-switch>
      </div>
      <div class="theme-switch">
        <app-theme-toggle></app-theme-toggle>
      </div>
    </div>
  </div>

  <div *ngIf="!isMenuExpanded" class="burger-container navbar-toggler col-1">
    <input type="checkbox" id="checkbox3" class="visuallyHidden checkbox3" data-bs-toggle="collapse"
           data-bs-target="#collapsibleNavbar" aria-label="Burger menu">
    <label for="checkbox3">
      <div class="hamburger hamburger3">
        <span class="bar bar1"></span>
        <span class="bar bar2"></span>
        <span class="bar bar3"></span>
        <span class="bar bar4"></span>
      </div>
    </label>
  </div>
</header>
