import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private language = new BehaviorSubject<string>('de');
  currentLanguage = this.language.asObservable();

  constructor() {
    const savedLanguage = localStorage.getItem('userLanguage') || (navigator.language === 'de' ? 'de' : 'en');
    this.language.next(savedLanguage);
  }

  changeLanguage(language: string) {
    this.language.next(language);
    localStorage.setItem('userLanguage', language);
  }

  getLanguage(): string {
    return localStorage.getItem('userLanguage') || (navigator.language === 'de' ? 'de' : 'en');
  }
}
