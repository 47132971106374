import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RequestMail} from '../dtos/request-mail';
import {Globals} from '../global/globals';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private mailBaseUri: string = this.globals.backendUri + '/mailRequest';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  sendMail(requestMail: RequestMail): Observable<any> {
    return this.httpClient.post(this.mailBaseUri, requestMail);
  }
}
