import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-vienna',
  templateUrl: './vienna.component.html',
  styleUrls: ['./vienna.component.scss']
})
export class ViennaComponent implements OnInit {
  vienna: any;
  title!: string;

  constructor(private http: HttpClient, private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });
  }

  loadContent(language: string) {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_vienna-' + language + '.json').subscribe(
      data => {
        this.vienna = data;
      },
      error => {
        console.log('Error loading vienna content for ' + language + ':', error);
      }
    );
  }

  toggleSources(data: any): void {
    data.source.isOpen = !data.source.isOpen;
  }

  loadImgStyle(image: any) {
    let imgStyles = {};

    switch (image.position) {
      case "left":
        imgStyles = {display: image.inline, marginRight: "auto"};
        break;
      case "right":
        imgStyles = {display: image.inline, marginLeft: "auto"};
        break;
      case "center":
        imgStyles = {marginLeft: "auto", marginRight: "auto"};
        break;
      default:
        break;
    }
    return imgStyles;
  }
}
