import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AnimateOnScrollDirective} from './directives/animate-on-scroll.directive';
import {HeaderComponent} from './component/header/header.component';
import {FaqComponent} from './component/faq/faq.component';
import {ViennaComponent} from './component/vienna/vienna.component';
import {HomeComponent} from './component/home/home.component';
import {LangSwitchComponent} from './component/lang-switch/lang-switch.component';
import {ThemeToggleComponent} from './component/theme-toggle/theme-toggle.component';
import {FooterComponent} from './component/footer/footer.component';
import {LoginComponent} from './component/login/login.component';

import {AppRoutingModule} from './app-routing.module';
import {httpInterceptorProviders} from './interceptors';
import {WeddingComponent} from './component/wedding/wedding.component';
import {LoginRedirectGuard} from "./guards/login.redirect.guard";
import {RouterLink, RouterModule} from "@angular/router";
import {ContactComponent} from './component/contact/contact.component';
import {MailService} from "./services/mail.service";
import {HttpClientModule} from "@angular/common/http";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FaqComponent,
    ViennaComponent,
    HomeComponent,
    LangSwitchComponent,
    ThemeToggleComponent,
    FooterComponent,
    LoginComponent,
    WeddingComponent,
    AnimateOnScrollDirective,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    RouterLink,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [httpInterceptorProviders, LoginRedirectGuard, MailService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

