import {Component, HostListener, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NavigationEnd, Router} from "@angular/router";
import {LanguageService} from "../../services/language.service";
import {filter} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMenuExpanded = false;
  items: any;
  lastScrollTop = 0;
  headerVisible = true;

  constructor(private http: HttpClient, protected router: Router, private languageService: LanguageService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.closeMenu();
    });
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });

    this.adjustHeaderVisibility();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.adjustHeaderVisibility();
  }

  private adjustHeaderVisibility() {
    const header = document.getElementById('header');
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > this.lastScrollTop && currentScrollTop > header.offsetHeight) {
      this.headerVisible = false;
    } else if (currentScrollTop < this.lastScrollTop || currentScrollTop <= header.offsetHeight) {
      this.headerVisible = true;
    }
    this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    header.classList.toggle('hidden', !this.headerVisible);
  }

  private loadContent(language: string) {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_header-' + language + '.json').subscribe(
      data => {
        this.items = data;
      },
      error => {
        console.error('Error loading header for ' + language + ':', error);
      }
    );
  }

  private closeMenu() {
    this.isMenuExpanded = false;
    const collapsibleNavbar = document.getElementById('collapsibleNavbar');
    if (collapsibleNavbar?.classList.contains('show')) {
      collapsibleNavbar.classList.remove('show');
    }
    const checkbox: HTMLInputElement = document.getElementById('checkbox3') as HTMLInputElement;
    if (checkbox.checked) {
      checkbox.checked = false;
    }
  }
}
