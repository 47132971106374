import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs!: any[];
  error!: string;
  title!: string;

  constructor(private http: HttpClient, private languageService: LanguageService) {
  }

  toggleAccordion(faq: any) {
    faq.isOpen = !faq.isOpen;
    faq.isExpanded = !faq.isExpanded;
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });
  }

  private loadContent(language: string) {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_faq-' + language + '.json').subscribe(
      data => {
        this.faqs = data;
      },
      error => {
        console.error('Error loading FAQs for ' + language + ':', error);
      }
    );
  }
}
