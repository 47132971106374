<div class="vienna container py-5">
  <h1 class="vienna-title">{{ title }}</h1>

  <div class="pb-3 hidden" appAnimateOnScroll *ngFor="let data of vienna">
    <div class="title-container pb-2">
      <h1 class="section-title text-center gwendolyn-bold title-fonts"> {{ data.section }}</h1>
      <div class="separator"></div>
    </div>

    <div class="section-container" *ngFor="let info of data.information">
      <h4 class="title-subsection hidden" appAnimateOnScroll>{{ info.subsection }}</h4>
      <p class="hidden" appAnimateOnScroll>{{ info.paragraph }}</p>

      <div *ngFor="let image of info.img " class="section-img d-flex hidden" appAnimateOnScroll>
        <div *ngIf="image.text && image.position == 'left'" class="section-img-text-right">
          {{ image.text }}
        </div>
        <img [src]="image.link" [style]="loadImgStyle(image)" [id]="image.id" [alt]="image.alt" [width]="image.width"
             [height]="image.height">
        <div *ngIf="image.text && image.position == 'right'" class="section-img-text-left">
          {{ image.text }}
        </div>
      </div>
    </div>

    <div class="source-container" *ngIf="data.source">
      <div class="source-title px-2" (click)="toggleSources(data)">
        <h5>Quellen</h5>
      </div>
      <div class="source-wrapper px-3" *ngIf="data.source.isOpen">
        <div *ngFor="let src of data.source.item">
          <a [href]="src.url" target="_blank" class="further-src">
            <p class="website">{{ src.website }} - {{ src.title }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
