<div class="wedding container py-5">
  <div class="source-wrapper" *ngFor="let content of data">
    <h3 class="text-center gwendolyn-regular subtitle-fonts">{{ content.timelineSmall }}</h3>
    <h1 class="text-center pb-5 gwendolyn-bold title-fonts">{{ content.timelineName }}</h1>

    <section class="timeline">
      <div class="container" *ngFor="let timeline of content.timeline">
        <div class="timeline-item">
          <div class="timeline-img"></div>
          <div class="timeline-content timeline-card js--fadeInRight rounded hidden"
               *ngIf="timeline.position == 'right'" appAnimateOnScroll>
            <div class="timeline-img-header" *ngIf="timeline.icon">
              <img class="timeline-icon rounded-top" [src]="timeline.icon" [alt]="timeline.alt">
              <h2 class="text-uppercase gold-style-text">{{ timeline.title }}</h2>
            </div>
            <h2 *ngIf="!timeline.icon">{{ timeline.title }}</h2>
            <div class="time rounded-tr">{{ timeline.time }}</div>
            <p class="pb-3" [innerHTML]="timeline.text"></p>
          </div>
        </div>
        <div class="timeline-item">
          <div class="timeline-img"></div>
          <div class="timeline-content timeline-card js--fadeInLeft rounded hidden" *ngIf="timeline.position == 'left'"
               appAnimateOnScroll>
            <div class="timeline-img-header" *ngIf="timeline.icon">
              <img class="timeline-icon rounded-top" [src]="timeline.icon" alt="{{timeline.alt}}">
              <h2 class="text-uppercase gold-style-text">{{ timeline.title }}</h2>
            </div>
            <h2 *ngIf="!timeline.icon">{{ timeline.title }}</h2>
            <div class="time rounded-tl">{{ timeline.time }}</div>
            <p [innerHTML]="timeline.text"></p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="map-container d-flex justify-content-between flex-wrap" *ngFor="let content of data">
    <div class="map-elem text-center">
      <h2 class="gwendolyn-bold title-fonts-medium">{{ content.mapDescription.church }}</h2>
      <iframe class="py-3"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2657.6279503946866!2d16.412684276320466!3d48.23303827125414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d06e673ffff97%3A0xc2c979e7b3065695!2sKirche%20Christus%20Hoffnung%20der%20Welt!5e0!3m2!1sde!2sat!4v1712266070972!5m2!1sde!2sat"
              allowfullscreen="" loading="lazy" title="Map of the church"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="map-elem text-center">
      <h2 class="gwendolyn-bold title-fonts-medium">{{ content.mapDescription.location }}</h2>
      <iframe class="py-3"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1117.3222278502308!2d16.412420804126544!3d48.236366443551354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d06efa0e56947%3A0x5ecb461803c40dd5!2swolke21%20im%20Saturn%20Tower!5e0!3m2!1sde!2sat!4v1712266227351!5m2!1sde!2sat"
              allowfullscreen="" loading="lazy" title="Map of the location"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</div>




