import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-wedding',
  templateUrl: './wedding.component.html',
  styleUrls: ['./wedding.component.scss']
})
export class WeddingComponent implements OnInit {
  title!: string;
  data: any;

  constructor(private http: HttpClient, private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });
  }

  private loadContent(language: string) {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_wedding-' + language + '.json').subscribe(
      data => {
        this.data = data;
      },
      error => {
        console.log('Error loading wedding content for ' + language + ':', error);
      }
    );
  }
}
