import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dayTicker!: number;
  hourTicker!: number;
  minuteTicker!: number;
  secondTicker!: number;
  targetDate!: Date;
  jsonData: any;

  logout(): void {
    //this.authService.logout();
  }

  constructor(private http: HttpClient, private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });

    this.targetDate = new Date('2024-10-05T14:00:00');
    this.updateCountdown();
  }

  private updateCountdown() {
    const currentTime = new Date().getTime();
    const timeDifference = this.targetDate.getTime() - currentTime;

    if (timeDifference >= 0) {
      this.dayTicker = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.hourTicker = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minuteTicker = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      this.secondTicker = Math.floor((timeDifference % (1000 * 60)) / 1000);

      requestAnimationFrame(() => {
        this.updateCountdown();
      });
    }
  }

  private loadContent(language: string) {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_home-' + language + '.json').subscribe(
      data => {
        this.jsonData = data;
      },
      error => {
        console.log('Error loading home content for ' + language + ':', error);
      }
    );
  }
}
