<div class="contact-container container py-5 hidden" appAnimateOnScroll>
  <div class="details-container pb-5">
    <div class="text-container hidden" *ngFor="let content of texts.info" appAnimateOnScroll>
      <p>{{ content.text }}</p>
    </div>
    <div class="sub-container">
      <div class="contact-info-1 text-center contact-box rounded hidden" appAnimateOnScroll>
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="name">{{ texts.bride_name }}</h5>
            <div class="number">{{ texts.bride_phone }}</div>
          </div>
          <div>
            <h5 class="name">{{ texts.groom_name }}</h5>
            <div class="number">{{ texts.groom_phone }}</div>
          </div>
        </div>
        <div class="text-center">
          <a class="mail url-red" href="mailto:{{texts.mail}}">{{ texts.mail }}</a>
        </div>
      </div>
    </div>
    <div class="sub-container pt-5 hidden" appAnimateOnScroll>
      <h1 class="title text-center hidden gwendolyn-regular title-fonts-medium"
          appAnimateOnScroll>{{ texts.WEDDING_PLANER_TITLE }}</h1>
      <div *ngFor="let content of texts.WEDDING_PLANER_CONTENT" class="hidden" appAnimateOnScroll>
        <p>{{ content.text }}</p>
      </div>
      <div class="contact-info-2 text-center contact-box rounded hidden mb-3" appAnimateOnScroll>
        <h5 class="name">{{ texts.wedding_planer_name }}</h5>
        <div class="number">{{ texts.wedding_planer_phone }}</div>
        <a class="mail url-red" href="mailto:{{texts.wedding_planer_mail}}">{{ texts.wedding_planer_mail }}</a>
      </div>
      <div class="mt-3 hidden text-center fw-bold" appAnimateOnScroll>
        <p>{{ texts.WEDDING_PLANER_CONTENT_ADD }}</p>
      </div>
    </div>
  </div>

  <div class="p-5 form-container rounded shadow">
    <h1 class="pb-3 text-center gwendolyn-bold title-fonts hidden" appAnimateOnScroll>{{ texts.FORM_TITLE }}</h1>
    <div *ngIf="successMessage" class="alert alert-success">{{ successMessage }}</div>
    <div *ngIf="contactError" class="alert alert-danger">{{ contactError }}</div>
    <form class="contact-form hidden" appAnimateOnScroll (ngSubmit)="sendMail()" #contactForm="ngForm">
      <div class="form-group hidden" appAnimateOnScroll>
        <label for="name">{{ texts.NAME_LABEL }}</label>
        <input type="text" id="name" class="form-control" [(ngModel)]="mailRequest.name" name="name"
               [placeholder]="texts?.NAME_PLACEHOLDER || ''">
      </div>
      <div class="form-group hidden" appAnimateOnScroll>
        <label for="contactInfo">{{ texts.CONTACT_INFO_LABEL }}</label>
        <input type="text" id="contactInfo" class="form-control" [(ngModel)]="mailRequest.reply" name="reply"
               [placeholder]="texts?.CONTACT_INFO_PLACEHOLDER || ''">
      </div>
      <div class="form-group hidden" appAnimateOnScroll>
        <label for="message">{{ texts.MESSAGE_LABEL }}</label>
        <textarea id="message" class="form-control" rows="4" [(ngModel)]="mailRequest.message" name="message"
                  [placeholder]="texts.MESSAGE_PLACEHOLDER" required #message="ngModel"></textarea>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!mailRequest.message" aria-label="Contact form submit button">{{ texts.SEND_BUTTON_TEXT }}
      </button>
    </form>
  </div>
</div>
