<div class="home container py-5">
  <div class="hidden" appAnimateOnScroll *ngFor="let data of jsonData">
    <div class="information-container rounded shadow py-4 px-5">
      <div [innerHTML]="data.info.title" class="text-center hidden gold-style-text" appAnimateOnScroll></div>
      <div *ngFor="let content of data.info.content">
        <div [innerHTML]="content.text" class="hidden" appAnimateOnScroll>
        </div>
      </div>
    </div>

    <div class="text-center pt-5">
      <div *ngFor="let subTitle of data.welcome.subtitle">
        <div [innerHTML]="subTitle.text" class="text-container" appAnimateOnScroll></div>
      </div>

      <img src="/assets/images/Johannes_und_Vivian.webp" class="img-container shadow m-4"
           alt="Johannes & Vivian - Groom & Bride" width="300px" height="340px">

      <div class="ticker-content row clock-container text-center rounded hidden" appAnimateOnScroll>
        <div class="countdown-title-container gwendolyn-bold title-fonts-medium">
          {{ data.countdown.title }}
        </div>
        <div class="ticker-container col">
          <h2 class="clock-timer gwendolyn-bold">{{ dayTicker }}</h2>
          <div *ngFor="let data of jsonData" class="clock-label"> {{ data.countdown.days }}</div>
        </div>
        <div class="clock-col col"></div>
        <div class="ticker-container col">
          <h2 class="clock-timer gwendolyn-bold">{{ hourTicker }}</h2>
          <div *ngFor="let data of jsonData" class="clock-label"> {{ data.countdown.hours }}</div>
        </div>
        <div class="clock-col col"></div>
        <div class="ticker-container col">
          <h2 class="clock-timer gwendolyn-bold">{{ minuteTicker }}</h2>
          <div *ngFor="let data of jsonData" class="clock-label"> {{ data.countdown.minutes }}</div>
        </div>
        <div class="clock-col col"></div>
        <div class="ticker-container col">
          <h2 class="clock-timer gwendolyn-bold">{{ secondTicker }}</h2>
          <div *ngFor="let data of jsonData" class="clock-label"> {{ data.countdown.seconds }}</div>
        </div>
      </div>

      <h1 class="title-container gwendolyn-bold title-fonts py-4 hidden gold-style-text"
          appAnimateOnScroll>{{ data.welcome.title }}</h1>
      <div class="information-text hidden text-w-600" [innerHTML]="infoData.text" appAnimateOnScroll
           *ngFor="let infoData of data.welcome.information">
      </div>
    </div>
  </div>
</div>
