import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {AuthRequest} from "../../dtos/auth-request";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password!: string;
  loginError: string | null = null;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
  }

  login(): void {
    if (this.password) {
      const authRequest = new AuthRequest('guest', this.password);
      this.authService.loginUser(authRequest).subscribe({
        next: () => {
          this.router.navigate(['/home']);
        },
        error: () => {
          this.showError('Das hat leider nicht geklappt!');
        }
      });
    }
  }

  private showError(message: string) {
    this.loginError = message;
    this.password = '';
    setTimeout(() => this.fadeOutError(), 3000);
  }

  private fadeOutError() {
    setTimeout(() => this.loginError = null, 500);
  }
}
