import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './component/login/login.component';
import {FaqComponent} from "./component/faq/faq.component";
import {HomeComponent} from "./component/home/home.component";
import {WeddingComponent} from "./component/wedding/wedding.component";
import {AuthGuard} from './guards/auth.guard';
import {LoginRedirectGuard} from "./guards/login.redirect.guard";
import {ContactComponent} from "./component/contact/contact.component";
import {ViennaComponent} from "./component/vienna/vienna.component";

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [LoginRedirectGuard]},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},
  {path: 'wedding', component: WeddingComponent, canActivate: [AuthGuard]},
  {path: 'contact', component: ContactComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
