import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {LanguageService} from "./services/language.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit, AfterViewInit {
  public showHeader = true;
  title!: string;
  headerHeight: number = 0;

  ngAfterViewInit() {
    this.headerHeight = document.getElementById('header').offsetHeight;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.languageService.changeLanguage(this.languageService.getLanguage());

    const storageKey = 'data-bs-theme';
    if (!localStorage.getItem(storageKey)) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.setAttribute(storageKey, 'dark');
        localStorage.setItem(storageKey, 'dark');
      } else {
        document.documentElement.setAttribute(storageKey, 'light');
        localStorage.setItem(storageKey, 'light');
      }
    }
  }

  constructor(private cdr: ChangeDetectorRef, private router: Router, private languageService: LanguageService) {
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      })
    });

    document.querySelector('.hidden');

    this.setInitialHeaderVisibility();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showHeader = !event.urlAfterRedirects.includes('/login');
      });
  }

  private setInitialHeaderVisibility(): void {
    const currentRoute = this.router.url;
    this.showHeader = !currentRoute.includes('/login');
  }

  scrollToTop(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
