import {Component, OnInit} from '@angular/core';
import {RequestMail} from "../../dtos/request-mail";
import {MailService} from "../../services/mail.service";
import {LanguageService} from "../../services/language.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  mailRequest: RequestMail = new RequestMail('', '', '');
  texts: any = {};
  contactError: string | "" = "";
  successMessage: string | "" = "";

  constructor(private mailService: MailService, private languageService: LanguageService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((language) => {
      this.loadContent(language);
    });
  }

  sendMail() {
    if (!this.mailRequest.message) {
      this.showError(this.texts.MESSAGE_REQUIRED);
      return;
    }
    this.mailService.sendMail(this.mailRequest).subscribe({
      next: () => {
        this.contactError = "";
        this.successMessage = this.texts.MESSAGE_SUCCESS;
        setTimeout(() => this.fadeOutSuccess(), 3000);
        this.mailRequest.message = "";
      },
      error: (err) => {
        this.showError(this.texts.MESSAGE_ERROR);
        console.log(err);
      }
    });
  }

  private fadeOutSuccess() {
    setTimeout(() => this.successMessage = "", 500); // Erfolgsmeldung nach 500ms ausblenden
  }

  private loadContent(language: string) {
    this.http.get<any>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_contact-' + language + '.json').subscribe({
      next: (texts) => {
        this.texts = texts;
      },
      error: (error) => {
        console.error(`Could not load translations for ${language}:`, error);
      }
    });
  }

  private showError(message: string) {
    this.contactError = message;
    setTimeout(() => this.fadeOutError(), 3000);
  }

  private fadeOutError() {
    setTimeout(() => this.contactError = "", 500);
  }
}
