<div class="language-picker language-picker-container d-flex">
  <button (click)="switchLanguage('de')" class="btn btn-custom btn-de" aria-label="Switch language to German button">
    DE
    <div class="lang-indicator rounded" [class.de]="localStorage.getItem('userLanguage') === 'de'"></div>
  </button>
  <button (click)="switchLanguage('en')" class="btn btn-custom btn-en" aria-label="Switch language to English button">
    EN
    <div class="lang-indicator rounded" [class.en]="localStorage.getItem('userLanguage') === 'en'"></div>
  </button>
  <!--<button (click)="switchLanguage('zh')" class="btn">CN</button>-->
</div>
