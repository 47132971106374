import {Component, OnInit} from '@angular/core';
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss']
})
export class LangSwitchComponent implements OnInit {
  protected readonly localStorage = localStorage;

  constructor(private languageService: LanguageService) {
  }

  ngOnInit(): void {
  }

  switchLanguage(language: string) {
    this.languageService.changeLanguage(language);
  }
}
