<div class="faq container py-5">
  <div class="faq-section pb-4 hidden" appAnimateOnScroll *ngFor="let content of faqs">
    <h2 class="text-center gwendolyn-bold title-fonts mb-3">{{ content.section }}</h2>

    <div class="accordion py-1 hidden" data-click="faq" *ngFor="let faq of content.faq" appAnimateOnScroll>
      <div
        class="faq-question-wrapper accordion-item bg-body-secondary p-3 d-flex justify-content-between question-bg align-items-center"
        (click)="toggleAccordion(faq)">
        <h5 class="faq-question accordion-header" [innerHTML]="faq.question"></h5>
        <div class="faq-question-icon">
          <div class="faq-icon-strip" [ngClass]="{'rotate-icon': faq.isExpanded}"></div>
        </div>
      </div>
      <div class="accordion-body faq-answer border rounded-bottom" [hidden]="!faq.isOpen"
           [innerHTML]="faq.answer"></div>
    </div>
  </div>
</div>
