import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  content: any;

  constructor(private http: HttpClient, protected router: Router) {
  }

  ngOnInit(): void {
    this.loadContent();
  }

  private loadContent() {
    this.http.get<any[]>('assets/i18n/0d80c893e0633333f2fdf2fab615fdcd_footer.json').subscribe(
      data => {
        this.content = data;
      },
      error => {
        console.error('Error loading footer:', error);
      }
    );
  }
}
